export function useViewportSize() {
  const { width } = useWindowSize();

  const isVs = computed(function () {
    return width.value < 900;
  });

  const isVsWide = computed(function () {
    return window.matchMedia(
      'screen and (max-width: 899px) and (min-aspect-ratio: 1/1)',
    ).matches;
  });

  const isVl = computed(function () {
    return width.value >= 900;
  });

  const isVlNarrow = computed(function () {
    return window.matchMedia(
      'screen and (min-width: 900px) and (max-aspect-ratio: 1/1)',
    ).matches;
  });

  return { isVs, isVl, isVsWide, isVlNarrow };
}
