<template>
  <img
    v-if="image && isSvg"
    class="image -svg"
    :src="image.url"
    :alt="image.alt ?? ''"
  />
  <NakedImage
    v-else-if="image && image.responsiveImage"
    picture-class="image"
    :data="image.responsiveImage"
  />
</template>

<script setup lang="ts">
import { NakedImage } from 'vue-datocms';
import type { ResponsiveImageFragment } from '#gql';

const props = defineProps<{
  image: {
    url?: string | undefined;
    alt?: string | null | undefined;
    mimeType?: string | undefined;
    responsiveImage?: ResponsiveImageFragment | null | undefined;
  };
}>();

const isSvg = computed(function () {
  return props.image?.mimeType === 'image/svg+xml';
});
</script>

<style scoped lang="scss"></style>
